<template>
  <v-app>
    <v-app-bar elevate-on-scroll color="white" app>
      <div class="d-flex align-center v-te">
        <div class="mvid"><span>metaverse</span>id</div>
      </div>

      <v-spacer></v-spacer>

      <!--
      <v-btn
        href="https://github.com/vuetifyjs/vuetify/releases/latest"
        target="_blank"
        text
      >
        <span class="mr-2">Latest Release</span>
        <v-icon>mdi-open-in-new</v-icon>
      </v-btn>
      -->
    </v-app-bar>

    <v-main>
      <HomePage />
    </v-main>
  </v-app>
</template>

<script>
import HomePage from './components/Home';

export default {
  name: 'App',

  components: {
    HomePage,
  },

  data: () => ({
    //
  }),
};
</script>

<style scoped>
@import url("https://fonts.googleapis.com/css2?family=MuseoModerno:wght@300&display=swap");

.mvid {
  font-family: "MuseoModerno", cursive;
  color: #fc7703;
  font-size: 24px;
}

.mvid span {
  color: #0388fc;
}
</style>